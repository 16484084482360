<template>
  <v-container  style="margin-top:90px;" class="pa-0"   fill-height fluid>
      <v-container  class="pa-0"  >
      
      <div>
            <div style="width:100%;background-color: #a5003d;"><center><img src="img/company_ilogo.png" style="width:45%"></center></div>
            <center><h1 class="akrosbold" style="    color: #a5003d; font-size:25px;">Imperial Tobacco у світі</h1></center>
            <p class="akro" style="padding: 10px;    text-align: justify;">Imperial Tobacco - це провідна міжнародна компанія, яка має розгалужений портфель товарів широкого споживання. Головним пріоритетним напрямком роботи є тютюнові вироби (сигарети, сигарили, різаний тютюн, папір для сигарет).</p>
            <p class="akro" style="padding: 10px;    text-align: justify;">Iсторія компанії бере свій початок у 1786 році - за понад 225 років компанія перетворилась з маленької тютюнової крамниці на провідну міжнародну компанію, до складу якої ввійшли понад 10 тютюнових компаній світового рівня (Rizla, Reemtsma, Commonwelth Brands, Altadis та багато інших).</p>
              <img src="img/company_i1.png" style="width:100%">
              <img src="img/company_i2.png" style="width:100%">
              <img src="img/company_i3.png" style="width:100%">
          </div>
      </v-container>

    </v-container>
</template>

<script>
import btnrnd from "../components/btnrnd.vue";
// eslint-disable-next-line no-unused-vars
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import { API_LOCATION } from "../../config";
export default {
  name: "Info",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    btnrnd,
  },
  data: () => ({
    faq:false,
    write:false,
    contact:false,
    
    
        accordion: false,
    popout: false,
    inset: false,
    multiple: false,
    disabled: false,
    readonly: false,
    focusable: false,
    flat: false,
    hover: false,
    tile: false, 
    req_tp:'',
        apiurl:API_LOCATION,
  }),
  computed: {
    task1: function () {
      return this.cards.filter((i) => i.type == "1");
    },
    task2: function () {
      return this.cards.filter((i) => i.type == "2");
    },
    task3: function () {
      return this.cards.filter((i) => i.type == "3");
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    authStatus: function () {
      return this.$store.getters.authStatus;
    },
    page_title: function () {
      return this.$store.getters.page_title;
    },
    fname: function () {
      return this.$store.getters.fname;
    },
    lname: function () {
      return this.$store.getters.lname;
    },
    points: function () {
      return this.$store.getters.points;
    },
    avatar: function () {
      return this.$store.getters.avatar;
    },
      title() {
        return this.$t('title')
      },
      faqs(){
        
        return[
          
          {'header':this.$t('q1'),'text':this.$t('a1')},
          {'header':this.$t('q2'),'text':this.$t('a2')},
          {'header':this.$t('q3'),'text':this.$t('a3')},
          {'header':this.$t('q4'),'text':this.$t('a4')},
          {'header':this.$t('q5'),'text':this.$t('a5')},
          {'header':this.$t('q6'),'text':this.$t('a6')},
          {'header':this.$t('q7'),'text':this.$t('a7')},
          {'header':this.$t('q8'),'text':this.$t('a8')},
          {'header':this.$t('q9'),'text':this.$t('a9')},
          {'header':this.$t('q10'),'text':this.$t('a10')}
        ]},
  },
  watch:{
      title: function(){
        this.$store.dispatch("page_title_set", this.title);
      }
  },
  mounted() {
     
  },
  created() {
    this.$store.dispatch("page_title_set", this.title);
    this.$store.dispatch("profileset");
  },
  methods:{
        save_request: function(){

            axios.post(this.apiurl,  { data: 'component_data',action: 'tpq_create', 'question':this.req_tp },{ headers: { 'content-type': 'application/x-www-form-urlencoded' } })
         .then(resp =>{
           
         if(resp.data=='OK'){ 
            //this.$store.commit('setSnack', 'Запит надіслано!')
            this.$router.push({ name: 'Profile'})
          }
          })
            
        },

    }
};
</script>


<i18n>
{
  
  "ua":{
      "title":"Про компанію",
      "btn1":"Надіслати повідомлення <br>до служби підтримки",
      "btn2":"Відповіді на часті питання",
      "btn3":"Надіслати повідомлення <br>торговому представнику",
      "close":"Закрити",
      "h_write":"Надіслати повідомлення до служби підтримки",
      "h_contact":"Надіслати повідомлення торговому представнику",
      "h_faq":"Відповіді на часті питання",
      "q1":"Чи можу я змінити номер телефону для участі у програмі?",
      "a1":"Так, це можливо. Зверніться, будь ласка, до Вашого Торгового Представника.",

      "q2":"Яку максимальну кількість балів я можу отримати за місяць?",
      "a2":"Максимальна кількість балів, яку можна отримати, залежить від типу Вашого контракту: <br>К300 – 300 балів, <br>К400 – 400 балів,<br>К500 – 500 балів. <br>Виконання екстра завдань дає можливість заробити додаткові бали в  доповнення до стандартної кількості балів контракту.",
      
      "q3":"Я зробив(-ла) помилку при виборі асортименту для участі. Чи можу я його змінити?",
      "a3":"Зміна асортименту для участі доступна раз на один квартал. Для уточнення інформації зверніться, будь ласка, до Вашого Торгового Представника.",

      "q4":"Чи зберігаються на наступний місяць мої накопичені бали?",
      "a4":"Так, зберігаються.",

      "q5":"Мені не нарахували бали за завдання «Фото вітрини» та «Мій товарний запас», у чому причина?",
      "a5":"Усі фото перевіряються вручну модератором, тому модерація може займати декілька днів. Точну інформацію Ви можете дізнатись у свого Торгового Представника.",

      "q6":"Які критерії враховуються при перевірці завдання «Фото вітрини»?",
      "a6":"Критеріями перевірки є  наявність усіх SKU, (відповідно до обраного асортименту) та правильність їх викладки.",

      "q7":"Якщо я беру участь в програмі як мережева торгова точка, скільки SKU має бути на фото вітрини?",
      "a7":"На фото вітрини має бути 32 SKU із актуального асортименту програми (актуальний асортимент уточнюйте у Вашого Торгового Представника).",

      "q8":"Які критерії враховуються при перевірці завдання «Мій товарний запас»?",
      "a8":"Критерієм перевірки є наявність на фото блоку кожного SKU із списку, наведеного у завданні, з нанесеним унікальним кодом.",

      "q9":"Я ввів(-ла) неправильний номер картки Fishka, чи можна його змінити?",
      "a9":"Так, змінити номер карти Fishka можна. Для цього достатньо звернутися у службу тех. підтримки iTeam.",

      "q10":"Як довго чекати на замовлені емоції?",
      "a10":"Поповнення мобільного рахунку та нарахування балів Fishka відбувається одразу автоматично, замовлені електронні сертифікати «Сільпо», «Єва» та «Розетка» з'являються у застосунку одразу."


      

  }
}
</i18n>